import React, { useEffect, useState } from "react";
import { useAuth } from "../hooks/AuthProvider.js";
import Navigation from "./navbar/Navigation.tsx";
import { useNavigate, useParams } from "react-router-dom";
import { getSubscriber, upsertSubscriber } from "../data/subscribers.js";
import {
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Stack,
  Text,
  useColorModeValue,
  Center
} from '@chakra-ui/react';
import { FiEdit } from "react-icons/fi";

const EditSubscriber = () => {
  const auth = useAuth();
  const navigate = useNavigate();
  const { subscriberId, newsletterId } = useParams();
  const [subscriber, setSubscriber] = useState(null);
  const boxColor = useColorModeValue('white', 'gray.700');

  useEffect(() => {
    getSubscriber(subscriberId, auth.user.id).then((subscriber) => setSubscriber(subscriber));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  async function saveSubscriber(event) {
    event.preventDefault();
    alert("Updating subscribers not yet implemented");
    // await upsertNewsletter(newsletter);
    //navigate("/subscribers/" + subscriberId); needs to be newsletter id
  }
    
  const children = getEditSubscriber();

  function getEditSubscriber() {    
    return (
      <form onSubmit={saveSubscriber}>
      <Flex h="80vh" padding={10} w={'full'}>
      <Stack spacing={4} w={'full'}>
        <Stack direction={['column', 'row']} w={'full'} spacing={8}>
          <FiEdit size={"4em"} />
          <Stack>
            <Heading lineHeight={1.1} fontSize={{ base: '2xl', sm: '3xl' }} align="left">
              Edit Subscriber
            </Heading>
            <Text align="left">I am not sure if I am going to keep this.</Text>
          </Stack>
        </Stack>

      <Stack
        spacing={4}
        w={'full'}
        maxW={'xl'}
        bg={boxColor}
        rounded={'xl'}
        boxShadow={'lg'}
        p={6}
        my={12}>

        <Stack>
          <FormControl id="name" isRequired>
            <FormLabel>Email</FormLabel>
            <Input
              placeholder="example@email.com"
              _placeholder={{ color: 'gray.500' }}
              type="text"
              value={subscriber?.email}
              onChange={(e) => setSubscriber({ ...subscriber, email: e.target.value })}
            />
          </FormControl>
        </Stack>

        <Center>
        <Stack spacing={6} direction={['column', 'row']}>
          <Button onClick={() => navigate("/subscribers/" + newsletterId)} colorScheme="red" w="3xs">
            Cancel
          </Button>
          <Button type="submit" colorScheme="blue" w="3xs">
            Save
          </Button>
        </Stack>
        </Center>
        
      </Stack>
      </Stack>
      </Flex>
      </form>
    );
  }

  return (
    <Navigation children={children}/>
  );
};

export default EditSubscriber;