import { firebaseApp } from "../firebase";
import { getFirestore, collection, getDoc, getDocs, doc, updateDoc, setDoc, query, where, serverTimestamp } from "firebase/firestore";
const db = getFirestore(firebaseApp);
const fetcher = (...args) => fetch(...args).then((res) => res.json());

async function getLetters(userId) {
  const q = query(collection(db, "letters"), where("userId", "==", userId));
  const snapshot = await getDocs(q);

  var letters = [];
  snapshot.forEach((doc) => {
    var letter = doc.data();
    letter.id = doc.id;
    letters.push(letter);
  });

  return letters;
}

async function getLetter(letterId, userId) {
  var letter = null;
  const ref = doc(db, "letters", letterId);
  const snapshot = await getDoc(ref);

  letter = snapshot.data();
  if (letter) {
    letter.id = snapshot.id;
  } else {
    // return an empty letter with the userId, will be a new letter
    letter = { userId: userId };
  }

  return letter;
}

async function insertLetter(letter) {
  const url = "https://createmailinglist-he2itdsdqa-uc.a.run.app/?name=" +
    encodeURIComponent(letter.name) + "&userid=" + letter.userId;

  try {
    const res = await fetcher(url);

    letter.externalId = res.id;
    letter.date = serverTimestamp();
  
    const ref = doc(collection(db, "letters"));
    await setDoc(ref, letter);
  } catch (error) {
    console.log(error);
    throw error;
  }

  return;
}

async function updateLetter(letter) {
  const ref = doc(db, "letters", letter.id);
  delete letter.id; // The ID is already the document key, no need to use it as a field
  await updateDoc(ref, letter);

  return;
}

async function upsertLetter(letter) {
  if (letter.id) {
    await updateLetter(letter);
  } else {
    await insertLetter(letter);
  }

  return;
}

export { getLetters, getLetter, upsertLetter, insertLetter, updateLetter };