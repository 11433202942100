import React, { useEffect, useState } from "react";
import { useAuth } from "../hooks/AuthProvider.js";
import Navigation from "./navbar/Navigation.tsx";
import { getNewsletter, upsertNewsletter } from "../data/newsletters.js";
import { useNavigate, useParams } from "react-router-dom";
import {
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Stack,
  Textarea,
  Text,
  useColorModeValue,
  Center
} from '@chakra-ui/react';
import { FiMail } from "react-icons/fi";

const EditNewsletter = () => {
  const auth = useAuth();
  const navigate = useNavigate();
  const { newsletterId } = useParams();
  const [newsletter, setNewsletter] = useState(null);
  const boxColor = useColorModeValue('white', 'gray.700');

  useEffect(() => {
    getNewsletter(newsletterId, auth.user.id).then((newsletter) => setNewsletter(newsletter));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  async function saveNewsletter(event) {
    event.preventDefault();
    await upsertNewsletter(newsletter);
    navigate("/newsletters");
  }
    
  const children = getEditNewsletter();

  function getEditNewsletter() {    
    return (
      <form onSubmit={saveNewsletter}>
      <Flex h="80vh" padding={10} w={'full'}>
      <Stack spacing={4} w={'full'}>
        <Stack direction={['column', 'row']} w={'full'} spacing={8}>
          <FiMail size={"4em"} />
          <Stack>
            <Heading lineHeight={1.1} fontSize={{ base: '2xl', sm: '3xl' }} align="left">
              Edit Newsletter
            </Heading>
            <Text align="left">Currently, it's not possible to change the design of the newsletter, only the logo.</Text>
          </Stack>
        </Stack>

      <Stack
        spacing={4}
        w={'full'}
        maxW={'4xl'}
        bg={boxColor}
        rounded={'xl'}
        boxShadow={'lg'}
        p={6}
        my={12}>

        <Stack>
          <FormControl id="name" isRequired>
            <FormLabel>Name</FormLabel>
            <Input
              placeholder="My first newsletter"
              _placeholder={{ color: 'gray.500' }}
              type="text"
              value={newsletter?.name}
              onChange={(e) => setNewsletter({ ...newsletter, name: e.target.value })}
            />
          </FormControl>
          <FormControl id="logo">
            <FormLabel>Logo</FormLabel>
            <Input
              placeholder="https://"
              _placeholder={{ color: 'gray.500' }}
              type="text"
              value={newsletter?.logo}
              onChange={(e) => setNewsletter({ ...newsletter, logo: e.target.value })}
            />
          </FormControl>
          <FormControl id="welcomeEmailSubject" isRequired>
            <FormLabel>Welcome Email Subject</FormLabel>
            <Input
              placeholder="Welcome to my newsletter"
              _placeholder={{ color: 'gray.500' }}
              type="text"
              value={newsletter?.welcomeEmail?.subject}
              onChange={(e) => setNewsletter({ ...newsletter, welcomeEmail: { ...newsletter?.welcomeEmail, subject: e.target.value } })}
            />
          </FormControl>
          <FormControl id="welcomeEmailBody" isRequired>
            <FormLabel>Welcome Email Body</FormLabel>
            <Textarea
              rows={'10'}
              placeholder="Thanks for joinig my newsletter"
              _placeholder={{ color: 'gray.500' }}
              value={newsletter?.welcomeEmail?.body}
              onChange={(e) => setNewsletter({ ...newsletter, welcomeEmail: { ...newsletter?.welcomeEmail, body: e.target.value } })}
            />
          </FormControl>
        </Stack>

        <Center>
        <Stack spacing={6} direction={['column', 'row']}>
          <Button onClick={() => navigate("/newsletters")} colorScheme="red" w={'3xs'}>
            Cancel
          </Button>
          <Button type="submit" colorScheme="blue" w={'3xs'}>
            Save
          </Button>
        </Stack>
        </Center>
      </Stack>   
      </Stack>
      </Flex>
      </form>
    );
  }

  return (
    <Navigation children={children}/>
  );
};

export default EditNewsletter;