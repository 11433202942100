import React, { useEffect } from "react";
import { useAuth } from "../hooks/AuthProvider.js";
import Navigation from "./navbar/Navigation.tsx";

const Billing = () => {
  const auth = useAuth();
  const children = "Billing";
  return (
    <Navigation children={children}/>
  );
};

export default Billing;