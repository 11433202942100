import React, { useEffect, useState } from "react";
import { useAuth } from "../hooks/AuthProvider.js";
import Navigation from "./navbar/Navigation.tsx";
import { getUserByAuth, updateUser } from "../data/users.js";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Stack,
  useColorModeValue,
  Avatar,
  Center,
} from '@chakra-ui/react';

const links = [
  { name: "Facebook", icon: "" },
  { name: "Twitter", icon: "" },
  { name: "Instagram", icon: "" },
  { name: "Tiktok", icon: "" },
  { name: "Youtube", icon: "" },
  { name: "Website", icon: "" }
];

const EditProfile = () => {
  const auth = useAuth();
  const navigate = useNavigate();
  const [user, setUser] = useState(null);

  useEffect(() => {
    getUserByAuth(auth.authId).then((user) => setUser(user));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  async function saveUser(event) {
    event.preventDefault();
    await updateUser(user);
    navigate("/profile");
  }
    
  const children = UserProfileEdit();

  function UserProfileEdit() {
    return (
      <form onSubmit={saveUser}>
      <Flex justifyContent="center" alignItems="center" h="80vh" w='full'>
      <Stack
        spacing={4}
        w={'full'}
        maxW={'2xl'}
        bg={useColorModeValue('white', 'gray.700')}
        rounded={'xl'}
        boxShadow={'lg'}
        p={6}
        my={12}>
        <Heading lineHeight={1.1} fontSize={{ base: '2xl', sm: '3xl' }}>
          Edit Profile
        </Heading>
        <FormControl id="userName">
          <FormLabel>Profile Picture</FormLabel>
          <Stack direction={['column', 'row']} spacing={6}>
            <Center>
              <Avatar size="xl" src={user?.profilePicture}>
              </Avatar>
            </Center>
            <Center w="full">
              <Button w="full">Change Image</Button>
            </Center>
          </Stack>
        </FormControl>
        <Stack direction={['row']} spacing={6} w={'full'}>
          <Stack>
            <FormControl id="firstName" isRequired>
              <FormLabel>First name</FormLabel>
              <Input
                placeholder="first name"
                _placeholder={{ color: 'gray.500' }}
                type="text"
                value={user?.firstName}
                onChange={(e) => setUser({ ...user, firstName: e.target.value })}
              />
            </FormControl>
            <FormControl id="lastName" isRequired>
              <FormLabel>Last name</FormLabel>
              <Input
                placeholder="last name"
                _placeholder={{ color: 'gray.500' }}
                type="text"
                value={user?.lastName}
                onChange={(e) => setUser({ ...user, lastName: e.target.value })}
              />
            </FormControl>
            <FormControl id="email" isRequired>
              <FormLabel>Email address</FormLabel>
              <Input
                placeholder="your-email@example.com"
                _placeholder={{ color: 'gray.500' }}
                type="email"
                value={user?.email}
                onChange={(e) => setUser({ ...user, email: e.target.value })}
              />
            </FormControl>
            <FormControl id="phone">
              <FormLabel>Phone</FormLabel>
              <Input
                type="text"
                value={user?.phone} 
                onChange={(e) => setUser({ ...user, phone: e.target.value })}
              />
            </FormControl>
          </Stack>

          <Stack>
            {links.map((link) => (
              <FormControl id={link.name} key={link.name}>
                <FormLabel>{link.name}</FormLabel>
                <Input
                  type="text"
                  value={user?.socialMedia?.[link.name]}
                  onChange={(e) => setUser({ ...user, socialMedia: { ...user.socialMedia, [link.name]: e.target.value } })}
                />
              </FormControl>
            ))}
          </Stack>
        </Stack>

        <Center>
        <Stack spacing={6} direction={['column', 'row']}>
          <Button onClick={() => navigate("/profile")} colorScheme="red" w="3xs">
            Cancel
          </Button>
          <Button type="submit" colorScheme="blue" w="3xs">
            Save
          </Button>
        </Stack>
        </Center>

      </Stack>
      </Flex>
      </form>
    );
  }

  return (
    <Navigation children={children}/>
  );
};

export default EditProfile;