import React, { useEffect, useState } from "react";
import { useAuth } from "../hooks/AuthProvider.js";
import Navigation from "./navbar/Navigation.tsx";
import { getPage, upsertPage } from "../data/pages.js";
import { getNewsletters } from "../data/newsletters.js";
import { useNavigate, useParams } from "react-router-dom";
import {
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Stack,
  HStack,
  useColorModeValue,
  Textarea,
  Select,
  Text,
  Center
} from '@chakra-ui/react';
import { FiStar } from "react-icons/fi";

const EditPage = () => {
  const auth = useAuth();
  const navigate = useNavigate();
  const { pageId } = useParams();
  const [page, setPage] = useState(null);
  const [newsletters, setNewsletters] = useState(null);

  useEffect(() => {
    getPage(pageId, auth.user.id).then((page) => setPage(page));
    getNewsletters(auth.user.id).then((newsletters) => setNewsletters(newsletters));

  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  async function savePage(event) {
    event.preventDefault();
    await upsertPage(page);
    navigate("/pages");
  }
    
  const children = GetEditPage();

  function GetEditPage() {
    return (
      <form onSubmit={savePage}>
      <Flex h="80vh" padding={10} w={'full'}>
      <Stack spacing={4} w={'full'}>
        <Stack direction={['column', 'row']} w={'full'} spacing={8}>
          <FiStar size={"4em"} />
          <Stack>
            <Heading lineHeight={1.1} fontSize={{ base: '2xl', sm: '3xl' }} align="left">
              Edit Landing Page
            </Heading>
            <Text align="left">Choose what call-to-action is displayed by what is filled out</Text>
          </Stack>
        </Stack>

      <Stack
        spacing={4}
        w={'full'}
        maxW={'3xl'}
        bg={useColorModeValue('white', 'gray.700')}
        rounded={'xl'}
        boxShadow={'lg'}
        p={6}
        my={12}>

        <Stack>
          <FormControl id="name" isRequired>
            <FormLabel>Name</FormLabel>
            <Input
              placeholder="My first page"
              _placeholder={{ color: 'gray.500' }}
              type="text"
              value={page?.name}
              onChange={(e) => setPage({ ...page, name: e.target.value })}
            />
          </FormControl>
          <FormControl id="url" isRequired>
            <FormLabel>URL</FormLabel>
            <HStack>
              <Text>https://tomeish.com/</Text>
              <Input
                placeholder="my-first-page"
                _placeholder={{ color: 'gray.500' }}
                type="text"
                value={page?.url}
                onChange={(e) => setPage({ ...page, url: e.target.value })}
              />
            </HStack>
          </FormControl>
          <FormControl id="title" isRequired>
            <FormLabel>Title</FormLabel>
            <Input
              placeholder="Thanks for reading my book"
              _placeholder={{ color: 'gray.500' }}
              type="text"
              value={page?.message?.title}
              onChange={(e) => setPage({ ...page, message: { ...page?.message, title: e.target.value } })}
            />
          </FormControl>
          <FormControl id="text" isRequired>
            <FormLabel>Body</FormLabel>
            <Textarea
              rows={"4"}
              placeholder="Please join my awesome newsletter"
              _placeholder={{ color: 'gray.500' }}
              value={page?.message?.text}
              onChange={(e) => setPage({ ...page, message: { ...page?.message, text: e.target.value } })}
            />
          </FormControl>
          <FormControl id="newsletter">
            <FormLabel>Newsletter</FormLabel>
            <Select
              value={page?.newsletterId}
              onChange={(e) => setPage({ ...page, newsletterId: e.target.value })}
            >
              <option value="">- None -</option>
              {newsletters?.map((letter) => (
                <option key={letter.id} value={letter.id}>{letter.name}</option>
              ))}
            </Select>
          </FormControl>
          <FormControl id="heroImage">
            <FormLabel>Hero Image</FormLabel>
            <Input
              placeholder="https://example.com/image.jpg"
              _placeholder={{ color: 'gray.500' }}
              type="text"
              value={page?.images?.hero}
              onChange={(e) => setPage({ ...page, images: { ...page?.images, hero: e.target.value } })}
            />
          </FormControl>
          <FormControl id="backgroundImage">
            <FormLabel>Background Image</FormLabel>
            <Input
              placeholder="https://example.com/image.jpg"
              _placeholder={{ color: 'gray.500' }}
              type="text"
              value={page?.images?.background}
              onChange={(e) => setPage({ ...page, images: { ...page?.images, background: e.target.value } })}
            />
          </FormControl>
          <FormControl id="amazonReviewLink">
            <FormLabel>Amazon Review Link</FormLabel>
            <Input
              placeholder="https://example.com/image.jpg"
              _placeholder={{ color: 'gray.500' }}
              type="text"
              value={page?.reviewLinks?.amazon}
              onChange={(e) => setPage({ ...page, reviewLinks: { ...page?.reviewLinks, amazon: e.target.value } })}
            />
          </FormControl>
          <FormControl id="goodreadsReviewLink">
            <FormLabel>Goodreads Review Link</FormLabel>
            <Input
              placeholder="https://example.com/image.jpg"
              _placeholder={{ color: 'gray.500' }}
              type="text"
              value={page?.reviewLinks?.goodreads}
              onChange={(e) => setPage({ ...page, reviewLinks: { ...page?.reviewLinks, goodreads: e.target.value } })}
            />
          </FormControl>
        </Stack>

        <Center>
        <Stack spacing={6} direction={['column', 'row']}>
          <Button onClick={() => navigate("/pages")} colorScheme="red" w="3xs">
            Cancel
          </Button>
          <Button type="submit" colorScheme="blue" w="3xs">
            Save
          </Button>
        </Stack>
        </Center>

      </Stack>
      </Stack>
      </Flex>
      </form>
    );
  }

  return (
    <Navigation children={children}/>
  );
};

export default EditPage;