import React, { useEffect, useState } from "react";
import { useAuth } from "../hooks/AuthProvider.js";
import Navigation from "./navbar/Navigation.tsx";
import { getLetters } from "../data/letters.js";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  Flex,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Stack,
  Heading,
  Text,
  Button,
  useColorModeValue
} from "@chakra-ui/react";
import { FiSend } from "react-icons/fi";

function GetLetters() {
  const auth = useAuth();
  const navigate = useNavigate();
  const { newsletterId } = useParams();
  const [letters, setLetters] = useState(null);

  useEffect(() => {
    getLetters(newsletterId).then((letters) => setLetters(letters));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Flex h="80vh" padding={10} w={'full'}>
      <Stack spacing={4} w={'full'}>
        <Stack direction={['column', 'row']} w={'full'} spacing={8}>
          <FiSend size={"4em"} />
          <Stack>
            <Heading lineHeight={1.1} fontSize={{ base: '2xl', sm: '3xl' }} align="left">
              Letters
            </Heading>
            <Text align="left">Scheduled and sent letters</Text>
          </Stack>
        </Stack>

      <Stack
      spacing={4}
      w={'full'}
      bg={useColorModeValue('white', 'gray.700')}
      rounded={'xl'}
      boxShadow={'lg'}
      p={6}
      my={12}>

        <TableContainer>
          <Table variant="simple">
            <Thead>
              <Tr>
                <Th>Letter</Th>
                <Th>Created</Th>
                <Th>Scheduled</Th>
                <Th>Edit</Th>
              </Tr>
            </Thead>
            <Tbody>
              {letters?.map((letter) => (
                <Tr key={letter.id}>
                  <Td>{letter.subject}</Td>
                  <Td>{letter.date?.toDate()?.toDateString()}</Td>
                  <Td>{letter.scheduled?.toDate()?.toDateString()}</Td>
                  <Td><Link class="table-link" to={"/letters/edit/" + letter.id + "/" + newsletterId}>Edit</Link></Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </TableContainer>

      </Stack>
      <Button bg={'blue.400'} color={'white'} w="75px" _hover={{ bg: 'blue.500', }} onClick={() => navigate("/letters/edit/new/" + newsletterId)}>Add</Button>
      
      </Stack>
    </Flex>
  )
}

const Letters = () => {
  const children = GetLetters();
  return (
    <Navigation children={children}/>
  );
};

export default Letters;