import { firebaseApp } from "../firebase";
import { getFirestore, collection, getDoc, getDocs, doc, updateDoc, setDoc, query, where, serverTimestamp } from "firebase/firestore";
const db = getFirestore(firebaseApp);
const fetcher = (...args) => fetch(...args).then((res) => res.json());

async function getNewsletters(userId) {
  const q = query(collection(db, "newsletters"), where("userId", "==", userId));
  const snapshot = await getDocs(q);

  var newsletters = [];
  snapshot.forEach((doc) => {
    var newsletter = doc.data();
    newsletter.id = doc.id;
    newsletters.push(newsletter);
  });

  return newsletters;
}

async function getNewsletter(newsletterId, userId) {
  var newsletter = null;
  const ref = doc(db, "newsletters", newsletterId);
  const snapshot = await getDoc(ref);

  newsletter = snapshot.data();
  if (newsletter) {
    newsletter.id = snapshot.id;
  } else {
    // return an empty newsletter with the userId, will be a new newsletter
    newsletter = { userId: userId };
  }

  return newsletter;
}

async function insertNewsletter(newsletter) {
  const url = "https://createmailinglist-he2itdsdqa-uc.a.run.app/?name=" +
    encodeURIComponent(newsletter.name) + "&userid=" + newsletter.userId;

  try {
    const res = await fetcher(url);

    newsletter.externalId = res.id;
    newsletter.date = serverTimestamp();
  
    const ref = doc(collection(db, "newsletters"));
    await setDoc(ref, newsletter);
  } catch (error) {
    console.log(error);
    throw error;
  }

  return;
}

async function updateNewsletter(newsletter) {
  const ref = doc(db, "newsletters", newsletter.id);
  delete newsletter.id; // The ID is already the document key, no need to use it as a field
  await updateDoc(ref, newsletter);

  return;
}

async function upsertNewsletter(newsletter) {
  if (newsletter.id) {
    await updateNewsletter(newsletter);
  } else {
    await insertNewsletter(newsletter);
  }

  return;
}

export { getNewsletters, getNewsletter, updateNewsletter, insertNewsletter, upsertNewsletter };