import React, { useEffect, useState } from "react";
import { useAuth } from "../hooks/AuthProvider.js";
import Navigation from "./navbar/Navigation.tsx";
import { getPromo, upsertPromo } from "../data/promos.js";
import { getPages } from "../data/pages.js";
import { useNavigate, useParams } from "react-router-dom";
import {
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Stack,
  Select,
  Text,
  useColorModeValue,
  Center
} from '@chakra-ui/react';
import { FiCompass } from "react-icons/fi";

const EditPromo = () => {
  const auth = useAuth();
  const navigate = useNavigate();
  const { promoId } = useParams();
  const [promo, setPromo] = useState(null);
  const [pages, setPages] = useState(null);
  const boxColor = useColorModeValue('white', 'gray.700');

  useEffect(() => {
    getPromo(promoId, auth.user.id).then((promo) => setPromo(promo));
    getPages(auth.user.id).then((pages) => setPages(pages));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  async function savePromo(event) {
    event.preventDefault();
    await upsertPromo(promo);
    navigate("/promos");
  }
    
  const children = GetEditPromo();

  function GetEditPromo() {    
    return (
      <form onSubmit={savePromo}>
      <Flex h="80vh" padding={10} w={'full'}>
      <Stack spacing={4} w={'full'}>
        <Stack direction={['column', 'row']} w={'full'} spacing={8}>
          <FiCompass size={"4em"} />
          <Stack>
            <Heading lineHeight={1.1} fontSize={{ base: '2xl', sm: '3xl' }} align="left">
              Edit Promo Document
            </Heading>
            <Text align='left'>This resulting PDF can be added to the end of your book</Text>
          </Stack>
        </Stack>

      <Stack
        spacing={4}
        w={'full'}
        maxW={'2xl'}
        bg={boxColor}
        rounded={'xl'}
        boxShadow={'lg'}
        p={6}
        my={12}>
        <Heading lineHeight={1.1} fontSize={{ base: '2xl', sm: '3xl' }}>
          Edit Promo
        </Heading>

        <Stack>
          <FormControl id="name" isRequired>
            <FormLabel>Name</FormLabel>
            <Input
              placeholder="My first promo"
              _placeholder={{ color: 'gray.500' }}
              type="text"
              value={promo?.name}
              onChange={(e) => setPromo({ ...promo, name: e.target.value })}
            />
          </FormControl>
          <FormControl id="title" isRequired>
            <FormLabel>Title</FormLabel>
            <Input
              placeholder="Join my newsletter!"
              _placeholder={{ color: 'gray.500' }}
              type="text"
              value={promo?.title}
              onChange={(e) => setPromo({ ...promo, title: e.target.value })}
            />
          </FormControl>
          <FormControl id="heroImage" isRequired>
            <FormLabel>Hero Image</FormLabel>
            <Input
              placeholder="https://example.com/image.png"
              _placeholder={{ color: 'gray.500' }}
              type="text"
              value={promo?.heroImage}
              onChange={(e) => setPromo({ ...promo, heroImage: e.target.value })}
            />
          </FormControl>
          <FormControl id="callToAction" isRequired>
            <FormLabel>Call To Action</FormLabel>
            <Input
              placeholder="Get early access to stories and content"
              _placeholder={{ color: 'gray.500' }}
              type="text"
              value={promo?.callToAction}
              onChange={(e) => setPromo({ ...promo, callToAction: e.target.value })}
            />
          </FormControl>
          <FormControl id="qrInstructions" isRequired>
            <FormLabel>QR Instructions</FormLabel>
            <Input
              placeholder="Scan me"
              _placeholder={{ color: 'gray.500' }}
              type="text"
              value={promo?.qrInstructions}
              onChange={(e) => setPromo({ ...promo, qrInstructions: e.target.value })}
            />
          </FormControl>
          <FormControl id="landingPage" isRequired> {/* In the future, have a way for any URL? */}
            <FormLabel>Langind Page</FormLabel>
              <Select
                value={promo?.landingPageId}
                onChange={(e) => setPromo({ ...promo, landingPageId: e.target.value })}
              >
                <option value="">- None -</option>
                {pages?.map((page) => (
                  <option key={page.id} value={page.id}>{page.name}</option>
                ))}
              </Select>
          </FormControl>
        </Stack>

        <Center>
        <Stack spacing={6} direction={['column', 'row']}>
          <Button onClick={() => navigate("/promos")} colorScheme="red" w="3xs">
            Cancel
          </Button>
          <Button type="submit" colorScheme="blue" w="3xs">
            Save
          </Button>
        </Stack>
        </Center>

      </Stack>

      </Stack>
      </Flex>
      </form>
    );
  }

  return (
    <Navigation children={children}/>
  );
};

export default EditPromo;