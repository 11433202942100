import React, { useEffect, useState } from "react";
import { useAuth } from "../hooks/AuthProvider.js";
import Navigation from "./navbar/Navigation.tsx";
import { getPromos } from "../data/promos.js";
import { Link, useNavigate } from "react-router-dom";
import {
  Flex,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Stack,
  Heading,
  Text,
  Button,
  useColorModeValue
} from "@chakra-ui/react";
import { FiCompass } from "react-icons/fi";

function GetPromos() {
  const auth = useAuth();
  const navigate = useNavigate();
  const [promos, setPromos] = useState(null);
  const tableColor = useColorModeValue('white', 'gray.700');

  useEffect(() => {
    getPromos(auth.user.id).then((promos) => setPromos(promos));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Flex h="80vh" padding={10} w={'full'}>
      <Stack spacing={4} w={'full'}>
        <Stack direction={['column', 'row']} w={'full'} spacing={8}>
          <FiCompass size={"4em"} />
          <Stack>
            <Heading lineHeight={1.1} fontSize={{ base: '2xl', sm: '3xl' }} align="left">
              Promo Documents
            </Heading>
            <Text align="left">Add to the end of your book to direct people to a landing page</Text>
          </Stack>
        </Stack>

      <Stack
      spacing={4}
      w={'full'}
      bg={tableColor}
      rounded={'xl'}
      boxShadow={'lg'}
      p={6}
      my={12}>

        <TableContainer>
          <Table variant="simple">
            <Thead>
              <Tr>
                <Th>Promo</Th>
                <Th>Created</Th>
                <Th>Edit</Th>
              </Tr>
            </Thead>
            <Tbody>
              {promos?.map((promo) => (
                <Tr key={promo.id}>
                  <Td>{promo.name}</Td>
                  <Td>{promo.date?.toDate()?.toDateString()}</Td>
                  <Td><Link class="table-link" to={"/promos/edit/" + promo.id + "/"}>Edit</Link></Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </TableContainer>

      </Stack>
      <Button bg={'blue.400'} color={'white'} w="75px" _hover={{ bg: 'blue.500', }} onClick={() => navigate("/promos/edit/new")}>Add</Button>

      </Stack>
    </Flex>
  )
}

const Promos = () => {
  const children = GetPromos();
  return (
    <Navigation children={children}/>
  );
};

export default Promos;