import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import PrivateRoute from "./router/route";
import Login from "./components/Login";
import Dashboard from "./components/Dashboard";
import Newsletters from "./components/Newsletters";
import Promos from "./components/Promos";
import Pages from "./components/Pages";
import Subscribers from "./components/Subscribers";
import EditSubscriber from "./components/EditSubscriber";
import Settings from "./components/Settings";
import AuthProvider from "./hooks/AuthProvider";
import Profile from "./components/Profile";
import Billing from "./components/Billing";
import Logout from "./components/Logout";
import EditProfile from "./components/EditProfile";
import EditPage from "./components/EditPage";
import EditNewsletter from "./components/EditNewsletter";
import EditPromo from "./components/EditPromo";
import Letters from "./components/Letters";
import EditLetter from "./components/EditLetter";

const App = () => {
  return (
    <div className="App">
      <Router>
        <AuthProvider>
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route element={<PrivateRoute />}>
              <Route path="/" element={<Dashboard />} />
              <Route path="/newsletters" element={<Newsletters />} />
              <Route path="/newsletters/edit/:newsletterId" element={<EditNewsletter />} />
              <Route path="/letters/:newsletterId" element={<Letters />} />
              <Route path="/letters/edit/:letterId/:newsletterId" element={<EditLetter />} />
              <Route path="/subscribers/:newsletterId" element={<Subscribers />} />
              <Route path="/subscribers/edit/:subscriberId/:newsletterId" element={<EditSubscriber />} />
              <Route path="/promos" element={<Promos />} />
              <Route path="/promos/edit/:promoId" element={<EditPromo />} />
              <Route path="/pages" element={<Pages />} />
              <Route path="/pages/edit/:pageId" element={<EditPage />} />
              <Route path="/settings" element={<Settings />} />
              <Route path="/profile" element={<Profile />} />
              <Route path="/profile/edit" element={<EditProfile />} />
              <Route path="/billing" element={<Billing />} />
              <Route path="/logout" element={<Logout />} />
            </Route>
            {/* Other routes */}
            <Route path="*" element={<Dashboard />} />
          </Routes>
        </AuthProvider>
      </Router>
    </div>
  );
};

export default App;
