import { useContext, createContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { firebaseApp } from "../firebase";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { getUserByAuth } from "../data/users";

const AuthContext = createContext();
const auth = getAuth(firebaseApp);

const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(JSON.parse(localStorage.getItem("user") || null));
  const [token, setToken] = useState(localStorage.getItem("token") || null);
  const navigate = useNavigate();

  function login(email, password) {
    return signInWithEmailAndPassword(auth, email, password)
    .then((userCredential) => {
      setToken(userCredential.user.accessToken);
      localStorage.setItem("token", userCredential.user.accessToken);

      getUserByAuth(userCredential.user.uid).then((user) => {
        setUser(user);
        localStorage.setItem("user", JSON.stringify(user));
      });

      navigate("/");
    })
    .catch((error) => {
      console.log(error.code, error.message);
      throw error;
    });
  }

  function logout() {
    setUser(null);
    setToken(null);
    localStorage.removeItem("user");
    localStorage.removeItem("token");
    navigate("/login");
  }

  return (
    <AuthContext.Provider value={{ token, user, login, logout }}>
      {children}
    </AuthContext.Provider>
  );

};

export default AuthProvider;

export const useAuth = () => {
  return useContext(AuthContext);
};