import { firebaseApp } from "../firebase";
import { getFirestore, collection, getDoc, getDocs, doc, deleteDoc, query, where } from "firebase/firestore";
const db = getFirestore(firebaseApp);
const fetcher = (...args) => fetch(...args).then((res) => res.json());

async function getSubscribers(newsletterId) {
  // add a limit for pagination
  const q = query(collection(db, "subscribers"), where("newsletterIds", "array-contains", newsletterId));
  const snapshot = await getDocs(q);

  var subscribers = [];
  snapshot.forEach((doc) => {
    var subscriber = doc.data();
    subscriber.id = doc.id;
    subscribers.push(subscriber);
  });

  return subscribers;
}

async function getSubscriber(subscriberId, newsletterId) {
  var subscriber = null;
  const ref = doc(db, "subscribers", subscriberId);
  const snapshot = await getDoc(ref);

  subscriber = snapshot.data();
  if (subscriber) {
    subscriber.id = snapshot.id;
  } else {
    // return an empty newsletter with the userId, will be a new newsletter
    subscriber = { newsletterId: newsletterId };
  }

  return subscriber;
}

async function insertSubscriber(subscriber) {
  // this call will add them to Brevo and the local database, and send them a welcome email
  const url = "https://addemailtolist-he2itdsdqa-uc.a.run.app/" + 
    "?email=" + encodeURIComponent(subscriber.email) + 
    "&listid=" + subscriber.newsletterId;

  try {
    const res = await fetcher(url);
    console.log(res);
  } catch (error) {
    console.log(error);
    throw error;
  }

  return;
}

async function deleteSubscriber(subscriberId) {
  const ref = doc(db, "subscribers", subscriberId);
  await deleteDoc(ref);

  return;
}

export { getSubscribers, getSubscriber, insertSubscriber, deleteSubscriber };
