import { firebaseApp } from "../firebase";
import { getFirestore, collection, getDoc, getDocs, doc, updateDoc, query, where } from "firebase/firestore";
const db = getFirestore(firebaseApp);

async function getUserByAuth(authId) {
  var user = null;
  const q = query(collection(db, "users"), where("authId", "==", authId));
  const snapshot = await getDocs(q);

  snapshot.forEach((doc) => {
    user = doc.data();
    user.id = doc.id;
  })

  return user;
};

async function getUser(userId) {
  var user = null;
  const ref = doc(db, "users", userId);
  const snapshot = await getDoc(ref);

  user = snapshot.data();
  user.id = snapshot.id;

  return user;
}

async function updateUser(user) {
  const ref = doc(db, "users", user.id);
  delete user.id; // The ID is already the document key, no need to use it as a field
  await updateDoc(ref, user);

  return;
}

export { getUserByAuth, getUser, updateUser };