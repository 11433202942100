import { firebaseApp } from "../firebase";
import { getFirestore, collection, getDoc, getDocs, doc, updateDoc, setDoc, query, where, serverTimestamp } from "firebase/firestore";
const db = getFirestore(firebaseApp);

async function getPages(userId) {
  const q = query(collection(db, "landingPages"), where("userId", "==", userId));
  const snapshot = await getDocs(q);

  var pages = [];
  snapshot.forEach((doc) => {
    var page = doc.data();
    page.id = doc.id;
    pages.push(page);
  });

  return pages;
}

async function getPage(pageId, userId) {
  var page = null;
  const ref = doc(db, "landingPages", pageId);
  const snapshot = await getDoc(ref);
  
  page = snapshot.data();
  if (page) {
    page.id = snapshot.id;
  } else {
    // return an empty page with the userId, will be a new page
    page = { userId: userId };
  }

  return page;
}

async function insertPage(page) {
  page.date = serverTimestamp();
  const ref = doc(collection(db, "landingPages"));
  await setDoc(ref, page);

  return;
}

async function updatePage(page) { 
  const ref = doc(db, "landingPages", page.id);
  delete page.id; // The ID is already the document key, no need to use it as a field
  await updateDoc(ref, page);

  return;
}

async function upsertPage(page) {
  if (page.id) {
    await updatePage(page);
  } else {
    await insertPage(page);
  }

  return;
}

export { getPages, getPage, insertPage, updatePage, upsertPage };