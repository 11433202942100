import React, { useEffect, useState } from "react";
import { useAuth } from "../hooks/AuthProvider.js";
import Navigation from "./navbar/Navigation.tsx";
import { getUserByAuth } from "../data/users.js";
import { useNavigate } from "react-router-dom";
import { SocialIcon } from 'react-social-icons'
import {
  Button,
  Flex,
  Heading,
  Stack,
  useColorModeValue,
  Avatar,
  Center,
  Text,
  HStack
} from '@chakra-ui/react';

const Profile = () => {
  const auth = useAuth();
  const navigate = useNavigate();
  const [user, setUser] = useState(null);

  useEffect(() => {
    getUserByAuth(auth.authId).then((user) => setUser(user));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const children = UserProfile();

  function UserProfile() {
    const name = user ? user.firstName + " " + user.lastName : null;

    return (
      <Flex justifyContent="center" alignItems="center" h="80vh">
        <Stack
          spacing={4}
          w={'full'}
          maxW={'md'}
          bg={useColorModeValue('white', 'gray.700')}
          rounded={'xl'}
          boxShadow={'lg'}
          p={6}
          my={12}>
          <Heading lineHeight={1.1} fontSize={{ base: '2xl', sm: '3xl' }}>
            Profile
          </Heading>
          <Stack direction={['row']} spacing={6}>
            <Center>
              <Avatar size="xl" src={user?.profilePicture}>
              </Avatar>
            </Center>
            <Stack spacing={3} direction={['column']}>
              <Text>{name}</Text>
              <Text>{user?.email??""}</Text>
              <Text>{user?.phone??""}</Text>
            </Stack>
          </Stack>
          <HStack>
            {Object.entries(user?.socialMedia ?? []).map(([social, url]) => (
              <SocialIcon key={social} url={url} />
            ))}
          </HStack>
          <Stack spacing={6} direction={['column', 'row']}>
            <Button onClick={() => navigate("edit")}
              bg={'blue.400'}
              color={'white'}
              w="full"
              _hover={{
                bg: 'blue.500',
              }}>
              Edit
            </Button>
          </Stack>
        </Stack>
      </Flex>
    );
  }

  return (
    <Navigation children={children}/>
  );
};

export default Profile;