import React, { useEffect } from "react";
import { useAuth } from "../hooks/AuthProvider.js";
import Navigation from "./navbar/Navigation.tsx";

const Settings = () => {
  const auth = useAuth();
  const children = "Settings";
  return (
    <Navigation children={children}/>
  );
};

export default Settings;