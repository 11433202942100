// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCMdxpm0zrp0wjbeIi1xRat5bp3u6htAbw",
  authDomain: "tomeish-220c9.firebaseapp.com",
  databaseURL: "https://tomeish-default-rtdb.firebaseio.com",
  projectId: "tomeish",
  storageBucket: "tomeish.appspot.com",
  messagingSenderId: "149786016331",
  appId: "1:149786016331:web:eeae4be86381f4028f6bce"
};

// rules_version = '2';

// service cloud.firestore {
//   match /databases/{database}/documents {
//     match /{document=**} {
//       allow read, write: if false;
//     }
//   }
// }

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);
const analytics = getAnalytics(firebaseApp);

export { firebaseApp };