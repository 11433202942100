import React/*, { useEffect }*/ from "react";
import { useAuth } from "../hooks/AuthProvider.js";
import Navigation from "./navbar/Navigation.tsx";
import {
  Flex,
  Heading,
  Text,
  Stack
} from '@chakra-ui/react';

const Dashboard = () => {
  const auth = useAuth();
  const children = (
    <Flex h="80vh" padding={10}>
      <Stack spacing={4}>
        <Heading>Welcome, {auth?.user?.firstName}!</Heading>
        <Text>Chose from the options on the right to get started.</Text>
      </Stack>
    </Flex>
  );
  
  return (
    <Navigation children={children}/>
  );
};

export default Dashboard;