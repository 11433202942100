import React, { useEffect, useState } from "react";
import { useAuth } from "../hooks/AuthProvider.js";
import Navigation from "./navbar/Navigation.tsx";
import { getLetter, upsertLetter } from "../data/letters.js";
import { useNavigate, useParams } from "react-router-dom";
import {
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Stack,
  Textarea,
  Text,
  useColorModeValue,
  Center
} from '@chakra-ui/react';
import { FiSend } from "react-icons/fi";

const EditLetter = () => {
  const auth = useAuth();
  const navigate = useNavigate();
  const { letterId,newsletterId } = useParams();
  const [letter, setLetter] = useState(null);
  const boxColor = useColorModeValue('white', 'gray.700');

  useEffect(() => {
    getLetter(letterId, newsletterId).then((letter) => setLetter(letter));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  async function saveLetter(event) {
    event.preventDefault();
    await upsertLetter(letter);
    navigate("/newsletters");
  }
    
  const children = getWriteLetter();

  function getWriteLetter() {    
    return (
      <form onSubmit={saveLetter}>
      <Flex h="80vh" padding={10} w={'full'}>
      <Stack spacing={4} w={'full'}>
        <Stack direction={['column', 'row']} w={'full'} spacing={8}>
          <FiSend size={"4em"} />
          <Stack>
            <Heading lineHeight={1.1} fontSize={{ base: '2xl', sm: '3xl' }} align="left">
              Write a New Letter
            </Heading>
            <Text align="left">Schedule a new letter to your subscribers.</Text>
          </Stack>
        </Stack>

      <Stack
        spacing={4}
        w={'full'}
        maxW={'3xl'}
        bg={boxColor}
        rounded={'xl'}
        boxShadow={'lg'}
        p={6}
        my={12}>

        <Stack>
          <FormControl id="subject" isRequired>
            <FormLabel>Subject</FormLabel>
            <Input
              placeholder="Update time"
              _placeholder={{ color: 'gray.500' }}
              type="text"
              value={letter?.subject}
              onChange={(e) => setLetter({ ...letter, subject: e.target.value })}
            />
          </FormControl>
          <FormControl id="body" isRequired>
            <FormLabel>Letter Body</FormLabel>
            <Textarea
              rows={"10"}
              placeholder="It's time for an update! Here's what's new..."
              _placeholder={{ color: 'gray.500' }}
              value={letter?.body}
              onChange={(e) => setLetter({ ...letter, body: e.target.value })}
            />
          </FormControl>
        </Stack>

        <Center>
        <Stack spacing={6} direction={['column', 'row']}>
          <Button onClick={() => navigate("/letters/" + newsletterId)} colorScheme="red" w="3xs">
            Cancel
          </Button>
          <Button type="submit" colorScheme="blue" w="3xs">
            Save
          </Button>
        </Stack>
        </Center>

      </Stack>
      </Stack>
      </Flex>
      </form>
    );
  }

  return (
    <Navigation children={children}/>
  );
};

export default EditLetter;