import React, { useEffect, useState } from "react";
import { useAuth } from "../hooks/AuthProvider.js";
import Navigation from "./navbar/Navigation.tsx";
import { getNewsletters } from "../data/newsletters.js";
import { Link, useNavigate } from "react-router-dom";
import {
  Flex,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Stack,
  Heading,
  Text,
  Button,
  useColorModeValue
} from "@chakra-ui/react";
import { FiMail } from "react-icons/fi";

function GetNewsletters() {
  const auth = useAuth();
  const navigate = useNavigate();
  const [newsletters, setNewsletters] = useState(null);

  useEffect(() => {
    getNewsletters(auth.user.id).then((newsletters) => setNewsletters(newsletters));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Flex h="80vh" padding={10} w={'full'}>
      <Stack spacing={4} w={'full'}>
        <Stack direction={['column', 'row']} w={'full'} spacing={8}>
          <FiMail size={"4em"} />
          <Stack>
            <Heading lineHeight={1.1} fontSize={{ base: '2xl', sm: '3xl' }} align="left">
              Newsletters
            </Heading>
            <Text align="left">Create different newsletters for different specific purposes</Text>
          </Stack>
        </Stack>

      <Stack
      spacing={4}
      w={'full'}
      bg={useColorModeValue('white', 'gray.700')}
      rounded={'xl'}
      boxShadow={'lg'}
      p={6}
      my={12}>

        <TableContainer>
          <Table variant="simple">
            <Thead>
              <Tr>
                <Th>Newsletter</Th>
                <Th>Created</Th>
                <Th>Edit</Th>
                <Th>Subscribers</Th>
                <Th>Letters</Th>
              </Tr>
            </Thead>
            <Tbody>
              {newsletters?.map((newsletter) => (
                <Tr key={newsletter.id}>
                  <Td>{newsletter.name}</Td>
                  <Td>{newsletter.date?.toDate()?.toDateString()}</Td>
                  <Td><Link class="table-link" to={"/newsletters/edit/" + newsletter.id + "/"}>Edit</Link></Td>
                  <Td><Link class="table-link" to={"/subscribers/" + newsletter.id + "/"}>Subscribers</Link></Td>
                  <Td><Link class="table-link" to={"/letters/" + newsletter.id + "/"}>Letters</Link></Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </TableContainer>

      </Stack>
      <Button bg={'blue.400'} color={'white'} w="75px" _hover={{ bg: 'blue.500', }} onClick={() => navigate("/newsletters/edit/new")}>Add</Button>
      
      </Stack>
    </Flex>
  )
}

const Newsletters = () => {
  const children = GetNewsletters();
  return (
    <Navigation children={children}/>
  );
};

export default Newsletters;