import React, { useEffect, useState } from "react";
import { useAuth } from "../hooks/AuthProvider.js";
import Navigation from "./navbar/Navigation.tsx";
import { getPages } from "../data/pages.js";
import { Link, useNavigate } from "react-router-dom";
import {
  Flex,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Stack,
  Heading,
  Text,
  Button,
  useColorModeValue
} from "@chakra-ui/react";
import { FiStar } from "react-icons/fi";

function GetPages() {
  const auth = useAuth();
  const navigate = useNavigate();
  const [landingPages, setLandingPages] = useState(null);

  useEffect(() => {
    getPages(auth.user.id).then((pages) => setLandingPages(pages));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Flex h="80vh" w={'full'} padding={10}>
      <Stack spacing={4} w={'full'}>
        <Stack direction={['column', 'row']} w={'full'} spacing={8}>
          <FiStar size={"4em"} />
          <Stack>
            <Heading lineHeight={1.1} fontSize={{ base: '2xl', sm: '3xl' }} align="left">
              Landing Pages
            </Heading>
            <Text align="left">A capture form for signing up for the newsletter, or other calls to action.</Text>
          </Stack>
        </Stack>

      <Stack
      spacing={4}
      w={'full'}
      bg={useColorModeValue('white', 'gray.700')}
      rounded={'xl'}
      boxShadow={'lg'}
      p={6}
      my={12}>
        <TableContainer>
          <Table variant="simple">
            <Thead>
              <Tr>
                <Th>Landing Page</Th>
                <Th>URL</Th>
                <Th>Created</Th>
                <Th>Edit</Th>
              </Tr>
            </Thead>
            <Tbody>
              {landingPages?.map((page) => (
                <Tr key={page.id}>
                  <Td>{page.name}</Td>
                  <Td><a class="table-link" href={"https://tomeish.com/" + page.url} target="_blank" rel="noreferrer">{"https://tomeish.com/" + page.url}</a></Td>
                  <Td>{page.date.toDate().toDateString()}</Td>
                  <Td><Link class="table-link" to={"/pages/edit/" + page.id + "/"}>Edit</Link></Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </TableContainer>

      </Stack>
      <Button bg={'blue.400'} color={'white'} w="75px" _hover={{ bg: 'blue.500', }} onClick={() => navigate("/pages/edit/new")}>Add</Button>

      </Stack>
    </Flex>
  )
}

const Pages = () => {
  useAuth();
  const children = GetPages();
  return (
    <Navigation children={children}/>
  );
};

export default Pages;