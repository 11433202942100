import { useRef } from "react";
import {
  Flex,
  Box,
  FormControl,
  FormLabel,
  Input,
  Stack,
  Link,
  Button,
  Heading,
  useColorModeValue,
} from "@chakra-ui/react";
import { useAuth } from "../hooks/AuthProvider";

export default function Login() {
  const emailRef = useRef();
  const passwordRef = useRef();
  const auth = useAuth();

  function handleLogin(event) {
    event.preventDefault();
    auth.login(emailRef.current.value, passwordRef.current.value)
    .catch((error) => {
      alert(error.message);
    });    
  }

  return (
    <Flex minH={'100vh'} align={'center'} justify={'center'} bg={useColorModeValue('gray.50', 'gray.800')}>
      <Stack spacing={8} mx={'auto'} maxW={'lg'} py={12} px={6}>
        <Stack align={'center'}>
          <Heading fontSize={'4xl'}>Welcome to Tomeish</Heading>
        </Stack>
        <Box rounded={'lg'} bg={useColorModeValue('white', 'gray.700')} boxShadow={'lg'} p={8}>
          <form onSubmit={handleLogin}>
            <Stack spacing={4}>
              <FormControl id="email">
                <FormLabel>Email address</FormLabel>
                <Input ref={emailRef} type="email" />
              </FormControl>
              <FormControl id="password">
                <FormLabel>Password</FormLabel>
                <Input ref={passwordRef} type="password" />
              </FormControl>
              <Stack spacing={10}>
                <Stack direction={{ base: 'column', sm: 'row' }} align={'start'} justify={'space-between'}>
                  <Link color={'blue.400'}>Forgot password?</Link>
                </Stack>
                <Button type="submit" bg={'blue.400'} color={'white'} _hover={{bg: 'blue.500',}}>
                  Sign in
                </Button>
              </Stack>
            </Stack>
          </form>
        </Box>
        <Box align={'right'}>
          <Link color="blue.400" href="#">
            Sign Up
          </Link>
        </Box>
      </Stack>
    </Flex>
  );

}