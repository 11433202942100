import { firebaseApp } from "../firebase";
import { getFirestore, collection, getDoc, getDocs, setDoc, doc, updateDoc, query, where, serverTimestamp } from "firebase/firestore";
const db = getFirestore(firebaseApp);

async function getPromos(userId) {
  const q = query(collection(db, "promoImages"), where("userId", "==", userId));
  const snapshot = await getDocs(q);

  var promos = [];
  snapshot.forEach((doc) => {
    var promo = doc.data();
    promo.id = doc.id;
    promos.push(promo);
  });

  return promos;
}

async function getPromo(promoId, userId) {
  var promo = null;
  const ref = doc(db, "promoImages", promoId);
  const snapshot = await getDoc(ref);
  
  promo = snapshot.data();
  if (promo) {
    promo.id = snapshot.id;
  } else {
    // return an empty promo with the userId, will be a new promo
    promo = { userId: userId };
  }

  return promo;
}

async function insertPromo(promo) {
  promo.date = serverTimestamp();
  const ref = doc(collection(db, "promoImages"));
  await setDoc(ref, promo);

  return;
}

async function updatePromo(promo) {
  const ref = doc(db, "promoImages", promo.id);
  delete promo.id; // The ID is already the document key, no need to use it as a field
  await updateDoc(ref, promo);

  return;
}

async function upsertPromo(promo) {
  if (promo.id) {
    await updatePromo(promo);
  } else {
    await insertPromo(promo);
  }

  return;
}

export { getPromos, getPromo, insertPromo, updatePromo, upsertPromo };